<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-page-jogotest">
          <b-row align-v="center">
            <b-col sm="1">
              <div class="icone-header-page">
                <img src="assets/images/modal_icons/3_jogo_teste.png" />
              </div>
            </b-col>

            <b-col>
              <h1 class="titulo-header-page text-white">
                Novo Jogo Teste
              </h1>
              <!-- <h4 class="text-white">Inicie ou marque um Jogo Teste:</h4> -->
            </b-col>
            <b-button
      v-ripple.400="'rgba(0, 0, 0, 0)'"
      variant="outline-secondary"
      class="button-right ml-1 botao-white-outline"
    >
      <feather-icon
        icon="Trash2Icon"
        class="mr-50"
      />
      <span class="align-middle ">Excluir</span>
    </b-button>
    <b-button
      v-ripple.400="'rgba(0, 0, 0, 0)'"
      variant="outline-primary"
      class="button-right ml-1 botao-white-preenchido "
    >
      <feather-icon
        icon="CheckIcon"
        class="mr-50"
      />
      <span class="align-middle">Concluir Consulta</span>
    </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-col md="12">
      <b-card-group columns>

        <b-card
          title="Inicie com Jogo Teste"
          class="position-static"
        >
          <b-card-text>
            Lorem ipsum dolor sit amet. Ex repellat eos impedit atque ea vitae fugit rem illum ipsa et voluptatibus excepturi non soluta Quis! Ea molestias nulla est dolor quidem qui expedita minus ab optio omnis ut velit nihil. 
          </b-card-text>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="lg"
          variant="primary"
        >
          Começar!
        </b-button>

          <b-card-text>
            
          </b-card-text>
        </b-card>
      </b-card-group>
    </b-col>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormTextarea,
  BTabs,
  BTab,
  BImg,
  BMedia,
  BButton,
} from 'bootstrap-vue'

import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required, email } from '@validations'
import { codeIcon } from './code'
import { BCalendar } from 'bootstrap-vue'
import {BTime} from 'bootstrap-vue'
import { BFormTimepicker } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardText,
    BButton,
    BImg,
    BMedia,
    BFormTextarea,
    BTabs,
    BCardText,
    BTab,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCalendar,
    BTime,
    BFormTimepicker
  },

  data() {
    return {
      UserSelecionado: '',
      TesteSelecionado: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      TipoTeste: [
        { value: 'testcog_tipo1', text: 'Tipo de Teste 1' },
        { value: 'testcog_tipo2', text: 'Tipo de Teste 2' },
        { value: 'testcog_tipo3', text: 'Tipo de Teste 3' },
        { value: 'testcog_tipo4', text: 'Tipo de Teste 4' },
        { value: 'testcog_tipo5', text: 'Tipo de Teste 5' },
        { value: 'testcog_tipo6', text: 'Tipo de Teste 6' },
        { value: 'testcog_tipo7', text: 'Tipo de Teste 7' },
        { value: 'testcog_tipo8', text: 'Tipo de Teste 8' },
      ],

      NomeUser: [
        { value: 'user1', text: 'Jonatan Neves' },
        { value: 'user2', text: 'Artur Tavares' },
        { value: 'user3', text: 'Henrique Formiga' },
        { value: 'user4', text: 'Johnny Rodrigues' },

      ],
      
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
